import React from 'react'
import Layout from '../components/Layout'
// import Image from 'gatsby-image'
// import Sidebar from '../components/Sidebar'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import SEO from '../components/SEO'
import Container from 'react-bootstrap/Container'
import NavBlogPosts from '../components/nav-blog-posts/nav-blog-posts'
import styled from 'styled-components' // 💅

const PostTemplate = ({ data, pageContext }) => {
  const {
    mdx: {
      frontmatter: { title, category, image, date },
      body,
      tableOfContents: { items },
      headings,
    },
  } = data

  let toc = []
  items &&
    items.map(heading => {
      toc.push(heading)
      if (heading.items) {
        heading.items.map(item => {
          toc.push(item)
        })
      }
    })

  const { prev, next } = pageContext
  return (
    // <Layout>
    //   <SEO
    //     title={title}
    //     // image={image && image.childImageSharp.fluid.src}
    //   />
    //   <StyledBlogPostTemplate>
    //     {/* {image ? <Image fluid={image.childImageSharp.fluid} /> : null} */}v
    //     <span className="float-left tag-emoji"> 🏷 </span>
    //     {category}
    //     <h2>{title}</h2>
    //     <p>{date}</p>
    //     <div className="underline"></div>
    //     <article>
    //       <MDXRenderer>{body}</MDXRenderer>
    //     </article>
    //     <aside>{/* <Sidebar /> */}</aside>
    //   </StyledBlogPostTemplate>
    // </Layout>
    <Layout>
      <SEO
        title={title}
        //     // image={image && image.childImageSharp.fluid.src}
      />
      <StyledBlogPostTemplate>
        <Container>
          <header>
            <div className="blog-post-category">
              <span className="tag-icon">🏷</span>
              <span className="tag">{category && category}</span>
            </div>
            <h1 className="blog-post-title">{title && title}</h1>
            <span className="blog-post-published-date">{date && date}</span>
          </header>
          {typeof items === 'undefined' ? null : (
            <aside className="table-of-contents">
              <h6
                style={{
                  marginLeft: `1.7rem`,
                  marginTop: `15px`,
                  marginBottom: `15px`,
                  color: `#636669`,
                }}
              >
                TABLE OF CONTENTS
              </h6>
              <ul className="toc-list">
                {toc.map(item => (
                  <li key={item.url}>
                    <a key={item.url} href={item.url}>
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            </aside>
          )}
          <MDXRenderer headings={headings}>{body && body}</MDXRenderer>
        </Container>
        <NavBlogPosts
          previous={prev && prev.frontmatter.slug}
          next={next && next.frontmatter.slug}
        />
      </StyledBlogPostTemplate>
    </Layout>
  )
}

export const query = graphql`
  query GetSinglePost($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        category
        date(formatString: "MMMM DD, YYYY")
        # image {
        #   childImageSharp {
        #     fluid {
        #       src
        #       ...GatsbyImageSharpFluid
        #     }
        #   }
        # }
      }
      body
      tableOfContents
      headings {
        depth
        value
      }
    }
  }
`

const StyledBlogPostTemplate = styled.div`
  padding-top: 220px;

  .toc-list {
    line-height: 1.3rem;
    margin-bottom: 30px;    
    overflow-y: scroll;
    padding-right: 30px;
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 768px;
    }
  }

  header {
    margin-bottom: 50px;
    line-height: 1.5;
  }

  p,
  ol {
    font-family: var(--ff-post-body);
    font-size: .95rem;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: -0.005em;
  }

  strong {
    font-weight: 400;
  }

  .blog-post-title {
    margin-bottom: 0;
    font-family: Rubik, sans-serif;
    font-weight: 300;
    color: #333;
  }

  .blog-post-published-date {
    display: block;
    font-family: Roboto, sans-serif;
    font-weight: 300;
    padding: 7px 0;
  }

  .blog-post-body {
    padding: 50px 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    // background: #c3c3cf;
    border-radius: 12px;
  }

  .tag-icon {
    display: inline;
    font-size: 24px;
    margin-right: 11px;
  }

  .tag {
    font-weight: 400;
    padding-bottom: 10px;
  }

  .blog-post-category {
    font-family: Rubik, sans-serif;
    text-transform: capitalize;
    // margin: 10px 0;
  }

  small {
    color: #2d3748;
    font-family: Rubik, sans-serif;
    padding-top: 8px;
    text-transform: lowercase;

    &::after {
      border: none;
      padding: 0;
    }
  }
`
// const StyledBlogPostTemplate = styled.section`
//   width: 85vw;
//   max-width: 1100px;
//   margin: 0 auto;
//   margin-bottom: 4rem;
//   .post-info {
//     margin: 2rem 0 4rem 0;
//     text-align: center;
//     span {
//       background: var(--clr-primary-5);
//       color: var(--clr-white);
//       border-radius: var(--radius);
//       padding: 0.25rem 0.5rem;
//       text-transform: uppercase;
//       letter-spacing: var(--spacing);
//     }
//     h2 {
//       margin: 1.25rem 0;
//       font-weight: 400;
//     }
//     p {
//       color: var(--clr-grey-5);
//     }
//     .underline {
//       width: 5rem;
//       height: 1px;
//       background: var(--clr-grey-9);
//       margin: 0 auto;
//       margin-bottom: 1rem;
//     }
//   }
//   @media (min-width: 992px) {
//     & {
//       width: 92vw;
//     }
//   }
//   @media (min-width: 1170px) {
//     & {
//       display: grid;
//       grid-template-columns: 1fr 200px;
//       column-gap: 4rem;
//     }
//   }
// `

export default PostTemplate
