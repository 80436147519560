import React from 'react'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { Link } from 'gatsby'
import * as styles from './nav-blog-posts.module.css'

const NavBlogPosts = ({ previous, next }) => (
  <Container>
    <hr className={styles.divider} />
    {/* <footer><Bio /></footer> */}
    <nav>
      <ul className={styles.navbuttonscontainer}>
        <li className="float-left">
          {previous && (
            <Button className={styles.navbuttons} variant="outline-dark">
              <Link to={`/posts/${previous}`} rel="prev">
                ← Previous
                {/* ← {previous.frontmatter.title} */}
              </Link>
            </Button>
          )}
        </li>
        <li className="float-right">
          {next && (
            <Button className={styles.navbuttons} variant="outline-dark">
              <Link to={`/posts/${next}`} rel="next">
                Next →{/* {next.frontmatter.title} → */}
              </Link>
            </Button>
          )}
        </li>
      </ul>
    </nav>
  </Container>
)

export default NavBlogPosts
